import {

  TOKEN_ABI,
  Contract_Address,
  Contract_ABI,
  SWAP_ABI,
  swap,
  Price2AddressUsdt,
  Price1Addressother,

} from "./config";
import { readContract, writeContract, fetchBalance, multicall } from "@wagmi/core";

import { toast } from "react-hot-toast";


const getAddress = {
  address: Contract_Address,
  abi: Contract_ABI,
};

export async function getIdToAddress(id) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "idToAddress",
      args: [id],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

// export async function getUserAllDetails(address) {
//   try {
//     const data = await readContract({
//       ...getAddress,
//       functionName: "users",
//       args: [address],
//     });

//     return data;
//   } catch (error) {
//     console.log(error);
//   }
// }

export async function isUserExist(address) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "isUserExists",
      args: [address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getTokenPrice() {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "getCurrentPrice",
      args: [[Price2AddressUsdt, Price1Addressother]],
    });

    const resdata = Number(data._hex) / 1e18;
    return resdata;
  } catch (error) {
    console.log(error);
  }
}

export async function getSploshPrice() {
  try {
    const data = await readContract({
      address: swap,
      abi: SWAP_ABI,
      functionName: "getPrice",
      args: [],
    });

    const resdata = Number(data._hex) / 1e18;
    return resdata;
  } catch (error) {
    console.log(error);
  }
}

export async function getBalanceUser(address, TOKEN_ADDRESS) {
  try {
    const balance = await fetchBalance({
      address: address,
      token: TOKEN_ADDRESS,
    });
    return balance;
  } catch (err) {
    console.log(err);
  }
}

async function registerDSC(walletAddress, referalAddress) {
  console.log(walletAddress, referalAddress, "datat::::")
  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getAddress,
    functionName: "registerbyDSC",
    args: [walletAddress, referalAddress],
    overrides: {
      // from: walletAddress,
      value: 0,
      // gasPrice: 1100000000,
    },
  });
  return res;
}

export async function registerDscToken(
  walletAddress,
  packageStaking,
  referalAddress,
  tokenAddress,
  setLoading,
  setRefresh,
) {

  try {

    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);
    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(packageStaking)) * 1e6
    );


    if (getBalance?.formatted >= allowanceval / 1e6) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await registerDSC(
          walletAddress,
          referalAddress
        );

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);
            setRefresh(true);
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 1100000000,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await registerDSC(
              walletAddress,
              referalAddress

            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
                console.log(d, "m");
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}

async function registerUSDT(walletAddress, referalAddress) {

  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getAddress,
    functionName: "registrationUSDT",
    args: [walletAddress, referalAddress],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 1100000000,
    },
  });
  return res;
}

export async function registerUsdtToken(
  walletAddress,
  packageStaking,
  referalAddress,
  tokenAddress,
  setLoading,
  setRefresh,
) {
  console.log(walletAddress,
    packageStaking,
    referalAddress, '2222')
  try {

    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);
    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(packageStaking)) * 1e18
    );


    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await registerUSDT(
          walletAddress,
          referalAddress
        );

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);
            setRefresh(true);
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 1100000000,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await registerUSDT(
              walletAddress,
              referalAddress

            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
                console.log(d, "m");
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}

async function registerstDSC(walletAddress, referalAddress) {

  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getAddress,
    functionName: "registerbystDSC",
    args: [walletAddress, referalAddress],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 1100000000,
    },
  });
  return res;
}
export async function registerstDscToken(
  walletAddress,
  packageStaking,
  referalAddress,
  tokenAddress,
  setLoading,
  setRefresh,
) {
  console.log(walletAddress,
    packageStaking,
    referalAddress, '111111')
  try {

    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);
    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(packageStaking)) * 1e18
    );


    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await registerstDSC(
          walletAddress,
          referalAddress
        );

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);
            setRefresh(true);
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 1100000000,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await registerstDSC(
              walletAddress,
              referalAddress

            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
                console.log(d, "m");
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}

async function activateDSC(walletAddress, amount,type) {

  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getAddress,
    functionName: "stakeDSC",
    args: [amount,type],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 1100000000,
    },
  });
  return res;
}

export async function activateDscToken(
  walletAddress,
  packageStaking,
  tokenAddress,
  type,
  tokenAmount,
  setLoading,
  setRefresh,
) {

  try {

    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);
    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(tokenAmount)) * 1e18
    );


    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await activateDSC(
          walletAddress,
          Number(packageStaking * 1e18).toLocaleString("fullwide", {
            useGrouping: false,
          }),
          type
        );

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);
            setRefresh(true);
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 1100000000,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await activateDSC(
              walletAddress,
              Number(packageStaking * 1e18).toLocaleString("fullwide", {
                useGrouping: false,
              }),
              type

            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
                console.log(d, "m");
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}


async function activateUSDT(walletAddress, amount,type) {
   console.log(walletAddress, amount,type,"walletAddress, amount,type")
  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getAddress,
    functionName: "stakeByUSDT",
    args: [amount,walletAddress],
    overrides: {
      //from: walletAddress,
      value: 0,
      // gasPrice: 1100000000,
    },
  });
  return res;
}


export async function activateUsdtToken(
  walletAddress,
  referrer,
  packageStaking,
  tokenAddress,
  type,
  tokenAmount,
  setLoading,
  setRefresh,
) {

  try {

    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);
    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(tokenAmount)) * 1e6
    );

console.log("referrer ",referrer)
console.log("packageStaking ",packageStaking)
console.log("allowanceval / 1e18 ",allowanceval / 1e6)
    //if (getBalance?.formatted >= allowanceval / 1e18) {
    if (allowanceval / 1e6 >= packageStaking) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await activateUSDT(
          referrer,
          Number(packageStaking * 1e18).toLocaleString("fullwide", {
            useGrouping: false,
          }),
          type
        );

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);
            setRefresh(true);
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 1100000000,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await activateUSDT(
              referrer,
              Number(packageStaking * 1e18).toLocaleString("fullwide", {
                useGrouping: false,
              }),
              type
            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
                console.log(d, "m");
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}


async function activatestDSC(walletAddress, amount,type) {

  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getAddress,
    functionName: "stakestDSC",
    args: [amount,type],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 1100000000,
    },
  });
  return res;
}

export async function activatestDSCToken(
  walletAddress,
  packageStaking,
  tokenAddress,
  type,
  tokenAmount,
  setLoading,
  setRefresh,
) {

  try {

    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);
    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(
      (Number(tokenAmount)) * 1e18
    );


    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await activatestDSC(
          walletAddress,
          Number(packageStaking * 1e18).toLocaleString("fullwide", {
            useGrouping: false,
          }),
          type
        );

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);
            setRefresh(true);
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 1100000000,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await activatestDSC(
              walletAddress,
              Number(packageStaking * 1e18).toLocaleString("fullwide", {
                useGrouping: false,
              }),
              type

            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
                console.log(d, "m");
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}





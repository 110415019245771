import React, { useEffect, useState, useRef } from "react";
//import {Link} from 'react-router-dom';
import { Nav, Tab } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getReferralHistory, getLevelIncome } from "../../../helpers/api";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { Loader } from "../Dashboard/Loader";


const ReferralBonus = () => {

  const options2 = [
    { value: "ALL", label: "ALL" },
    { value: "1", label: "Level 1" },
    { value: "2", label: "Level 2" },
    { value: "3", label: "Level 3" },
    { value: "4", label: "Level 4" },
    { value: "5", label: "Level 5" },
    { value: "6", label: "Level 6" },
    { value: "7", label: "Level 7" },
    { value: "8", label: "Level 8" },
    { value: "9", label: "Level 9" },
    { value: "10", label: "Level 10" },
    { value: "11", label: "Level 11" },
    { value: "12", label: "Level 12" },
    { value: "13", label: "Level 13" },
    { value: "14", label: "Level 14" },
    { value: "15", label: "Level 15" },
    { value: "16", label: "Level 16" },
    { value: "17", label: "Level 17" },
    { value: "18", label: "Level 18" },
    { value: "19", label: "Level 19" },
    { value: "20", label: "Level 20" },

  ];
  const [selectedOption, setSelectedOption] = useState({
    value: "ALL",
    label: "ALL",
  });
  const { walletAddress, userToken } = useSelector((action) => {
    return action.auth;
  });
  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  function getLevelData(p) {
    getReferralHistory(walletAddress, selectedOption.value, p).then((res) => {

      console.log(res, "res::cdjjdj")
      setTotal(res?.record_count);
      setApidata(res?.data);
      setLoading(true);
    });
  }

  useEffect(() => {
    getLevelData(page);

  }, [walletAddress, page, selectedOption]);

  const handleChange = (e, p) => {
    getLevelData(p);
    //console.log(p, page, "page:::");
    setPage(p);
  };

  return (
    <div>
      {!loading ? (
        <Loader />
      ) : (
        <Tab.Container defaultActiveKey="Buy">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                {/* <div className="card-body pb-0">
                  <div className="d-flex flex-wrap">
                    <Select
                      options={options2}
                      defaultValue={options2[0]}
                      onChange={setSelectedOption}
                      className="custom-react-select ms-4 me-2"
                    />
                  </div>
                </div> */}
                <div className="card-body">
                  <Tab.Content>
                    <Tab.Pane eventKey="Buy">
                      <Tab.Container defaultActiveKey="BTH">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="heading text-white">Referral Bonus</h4>
                        </div>
                        <Tab.Content>
                          <Tab.Pane eventKey="BTH">
                            <div className="table-responsive dataTablehistory">
                              <div
                                id="bthdata_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table
                                  id="example"
                                  className="table dataTable shadow-hover display"
                                  style={{ minWidth: "845px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th className="text-white">Txn Hash</th>

                                      <th className="text-white">Sender</th>
                                      <th className="text-white">User Id</th>
                                      <th className="text-white">Level</th>
                                      <th className="text-white">Total amount($)</th>
                                      {/* <th className="text-white">Percent(%)</th> */}
                                      <th className="text-white">Net Payable Amount ($)</th>
                                      {/* <th className="text-white">Type</th> */}
                                      <th className="text-white">Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {apidata?.length > 0 ? (
                                      apidata.map((e, ind) => {

                                        return (
                                          <tr key={ind}>
                                            <td>
                                              {e?.txHash?.slice(0, 6)}...
                                              {e?.txHash?.slice(-5)}
                                              <a
                                                target="_blank"
                                                href={`https://polygonscan.com/tx/${e?.txHash}`}
                                              >
                                                <i className="fas fa-external-link-alt fs-10 ms-1 text-success"></i>
                                              </a>
                                            </td>

                                            <td>
                                              {e?.sender?.slice(0, 6)}...
                                              {e?.sender?.slice(-5)}
                                            </td>
                                            <td>{e.userId}</td>

                                            <td>{e.level}</td>
                                            <td>{e.amount} </td>

                                            {/* <td>{e.percent}</td> */}

                                          <td>  {e?.income?.toFixed(3)}</td>
                                           {/* <span  className="badge bg-success">{e.income_type} </span> */}
                                            {/* <td> {e.income_type}  </td> */}
                                            {/* <td>
                                              {moment(
                                                (e.createdAt)
                                              ).fromNow()}
                                            </td> */}
                                            <td>
                                              {moment(e.createdAt).format('MMMM Do YYYY')}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan={7}>
                                          <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                            No Data Found.
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="application-tbl1_paginate"
                                  >
                                    <Pagination
                                      color="primary"
                                      count={Math.ceil(total / 10)}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </div>
          </div>
        </Tab.Container>
      )}
    </div>
  );
};

export default ReferralBonus;
